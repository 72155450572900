import React, { useEffect, useState } from "react";
import { SchemesApi } from "apis/SchemesApi";
import { ProductsApi } from "apis/ProductsApi";
import { BrandsApi } from "apis/BrandsApi";
import { GroupsApi } from "apis/GroupsApi";
import { ToastContainer, toast } from "react-toastify";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import DialogActions from "@mui/material/DialogActions";
import { useNavigate } from "react-router";
import AddSchemesTable from "components/Tables/AddSchemesTable";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import TableHeader from "components/Table/TableHeader";
import TableElement from "components/Table/TableElement";
import { formatDate } from "utils";
import {
  createSvgIcon,
  DialogContent,
  Grid,
  useMediaQuery,
} from "@mui/material";
import AutoComplete from "components/AutoComplete";
import { useDispatch, useSelector } from "react-redux";
import CircularIndeterminateModal from "../../utils/Loading";
import { setLoadingPanel } from "../../redux/features/loading";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const LastDayOfMonth = (y, m) => {
  return new Date(y, m, 0).getDate();
};

export default function Schemes() {
  const navigate = useNavigate();
  const date = new Date();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const day = date.getDate();
  const color = "light";
  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState("");
  const [open, setOpen] = React.useState(false);
  const [schemes, setSchemes] = useState([]);

  const periodFrom = `${year}-${month > 9 ? month : "0" + month}-01`;
  // const periodTo = `${year}-${month > 9 ? month : "0" + month}-${LastDayOfMonth(
  //   year,
  //   month
  // )}`;
  const periodTo = `${year}-${month > 9 ? month : "0" + month}-${LastDayOfMonth(
    year,
    month
  )}`;
  const [datePayload, setDatePayload] = useState({
    periodFrom: periodFrom,
    periodTo: periodTo,
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [totalElements, setTotalElements] = useState("");

  useEffect(() => {
    getBrands();
  }, []);

  const getBrands = () => {
    dispatch(setLoadingPanel(true));
    BrandsApi.GetBrandsListByFilter({
      page: 0,
      size: 20,
    })
      .then((res) => {
        setBrands(res.data.data.filter((d) => d.isActive));
        const brandsArray = res.data.data.filter((d) => d.isActive);
        setSelectedBrand(brandsArray[0]);
        getSchemes(res.data.data[0].id, 0);
        dispatch(setLoadingPanel(false));
      })
      .catch((err) => {
        dispatch(setLoadingPanel(false));
        if (err?.response?.data) {
          toast(err.response.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
              maxWidth: "90vw",
              width: "auto",
              margin: "5px  auto",
              padding: "5px 5px",
              fontSize: "14px",
            },
            closeButton: (
              <div className="flex justify-end">
                <button className="text-gray-500 hover:text-gray-800 focus:outline">
                  <CloseIcon className="w-7 h-7" />
                </button>
              </div>
            ),
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
              maxWidth: "90vw",
              width: "auto",
              margin: "5px  auto",
              padding: "5px 5px",
              fontSize: "14px",
            },
            closeButton: (
              <div className="flex justify-end">
                <button className="text-gray-500 hover:text-gray-800 focus:outline">
                  <CloseIcon className="w-7 h-7" />
                </button>
              </div>
            ),
          });
        }
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const getSchemes = (id, page) => {
    if (id) {
      SchemesApi.GetByFilters({
        page: page,
        size: 20,
        brandId: id,

        periodFrom: datePayload.periodFrom
          ? datePayload.periodFrom
          : periodFrom,
        periodTo: datePayload.periodTo ? datePayload.periodTo : periodTo,
      })
        .then((res) => {
          setSchemes(res.data.data);
          setTotalElements(res.data.totalElements);
          setTotalPages(res.data.pages);
        })
        .catch((err) => {
          if (err?.response?.data) {
            toast(err.response.status + " " + err?.response?.data?.message, {
              type: "error",
              position: toast.POSITION.TOP_CENTER,
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              style: {
                maxWidth: "90vw",
                width: "auto",
                margin: "5px  auto",
                padding: "5px 5px",
                fontSize: "14px",
              },
              closeButton: (
                <div className="flex justify-end">
                  <button className="text-gray-500 hover:text-gray-800 focus:outline">
                    <CloseIcon className="w-7 h-7" />
                  </button>
                </div>
              ),
            });
          } else {
            toast(err?.response?.data?.message, {
              type: "error",
              position: toast.POSITION.TOP_CENTER,
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              style: {
                maxWidth: "90vw",
                width: "auto",
                margin: "5px  auto",
                padding: "5px 5px",
                fontSize: "14px",
              },
              closeButton: (
                <div className="flex justify-end">
                  <button className="text-gray-500 hover:text-gray-800 focus:outline">
                    <CloseIcon className="w-7 h-7" />
                  </button>
                </div>
              ),
            });
          }
          if (err.response.status === 401) {
            localStorage.clear();
            navigate("/");
          }
        });
    }
  };

  useEffect(() => {
    getSchemes(selectedBrand.id, 0);
  }, [datePayload]);

  const onSchemeSearch = (e) => {
    SchemesApi.SearchByKeyword({
      page: 0,
      size: 20,
      brandId: selectedBrand.id,
      periodFrom: periodFrom,
      periodTo: periodTo,
      keyword: e.target.value,
    })
      .then((res) => {
        setSchemes(res.data.data);
      })
      .catch((err) => {
        if (err?.response?.data) {
          toast(err.response.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        }
        if (err.response.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const handleChange = (event) => {
    setSelectedBrand(event.target.value);
    getSchemes(event.target.value.id, 0);
  };

  const addScheme = () => {
    setOpen(true);
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);

    getSchemes(selectedBrand.id, page - 1);
    return currentPage;
  };
  const PlusIcon = createSvgIcon(
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 4.5v15m7.5-7.5h-15"
      />
    </svg>,
    "Plus"
  );

  const clearSearch = () => {
    setDatePayload({
      periodFrom: "",
      periodTo: "",
    });
    getSchemes(selectedBrand.id, 0);
  };

  const isMobile = useMediaQuery("(max-width:768px)");
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loadingReducer.isLoading);

  return (
    <>
      <CircularIndeterminateModal open={isLoading && !brands.length} />
      <div
        className={`
        relative flex  flex-col  items-center md:pt-8 md:px-8 
        min-w-0 break-words w-full mb-6 shadow-lg rounded
        ${color === "light" ? "bg-white" : "bg-lightBlue-900 text-white"}
      `}
      >
        <div className="flex xl:flex-row flex-col gap-3 md:gap-4 w-full">
          <div className="w-full xl:w-[80%] p-4 md:px-8 md:py-4">
            <Box className="w-full">
              <FormControl fullWidth>
                <InputLabel
                  id="demo-simple-select-label"
                  className="text-sm md:text-base"
                >
                  Select Brand
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedBrand}
                  label={
                    <span className="text-sm md:text-base font-medium">
                      Brand
                    </span>
                  }
                  onChange={handleChange}
                  sx={{
                    fontSize: {
                      xs: "12px",
                      sm: "13px",
                      md: "13px",
                      lg: "16px",
                    },
                  }}
                  className="min-h-[40px] md:min-h-[52px]"
                >
                  {brands.map((b, i) => (
                    <MenuItem
                      key={i}
                      value={b}
                      sx={{
                        fontSize: {
                          xs: "12px",
                          sm: "13px",
                          md: "13px",
                          lg: "16px",
                        },
                      }}
                    >
                      {b.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </div>
          <div className="w-full xl:w-[30%] md:px-8 md:py-4 p-4 flex items-center">
            <Button
              onClick={addScheme}
              fullWidth
              variant="contained"
              color="primary"
              sx={{
                height: { xs: "40px", md: "52px" },
                fontSize: {
                  xs: "12px",
                  sm: "13px",
                  md: "13px",
                  lg: "16px",
                },
              }}
              startIcon={<PlusIcon className="h-4 w-4 md:h-5 md:w-5" />}
            >
              Add Scheme
            </Button>
          </div>
        </div>
        <div className="flex xl:flex-row flex-col gap-2 w-full md:px-8 md:py-4 p-4">
          <Grid sx={{ width: { xl: "50%", xs: "100%" } }}>
            <TextField
              type="date"
              onChange={(e) => {
                setDatePayload((prev) => ({
                  ...prev,
                  periodFrom: e.target?.value,
                }));
              }}
              value={datePayload?.periodFrom}
              fullWidth
              id="outlined-basic"
              helperText="Start Date"
              variant="outlined"
              InputProps={{
                sx: {
                  fontSize: {
                    xs: "12px",
                    sm: "13px",
                    md: "13px",
                    lg: "16px",
                  },
                },
              }}
            />
          </Grid>
          <Grid sx={{ width: { xl: "50%", xs: "100%" } }}>
            <TextField
              type="date"
              onChange={(e) => {
                setDatePayload((prev) => ({
                  ...prev,
                  periodTo: e.target?.value,
                }));
              }}
              value={datePayload?.periodTo}
              fullWidth
              id="outlined-basic"
              helperText="End Date"
              variant="outlined"
              InputProps={{
                sx: {
                  fontSize: {
                    xs: "12px",
                    sm: "13px",
                    lg: "16px",
                  },
                },
              }}
            />
          </Grid>
        </div>
        <div className="flex xl:flex-row flex-col gap-2 w-full md:px-8 md:py-4 md:mb-2 p-4">
          <Grid
            container
            spacing={2}
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <Grid
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                size="medium"
                color="secondary"
                onClick={clearSearch}
                sx={{
                  height: { xs: "40px", md: "52px" },
                  fontSize: {
                    xs: "12px",
                    sm: "13px",
                    md: "13px",
                    lg: "16px",
                  },
                }}
              >
                Reset All
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>

      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="block w-full overflow-x-auto p-4 md:p-8">
          <div className="mb-3 pt-0">
            <input
              onChange={onSchemeSearch}
              type="text"
              placeholder="Search Scheme"
              className="p-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full"
            />
          </div>
        </div>
      </div>

      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="block w-full overflow-x-auto py-3 px-4 lg:p-8">
          <Stack
            spacing={2}
            direction={{ md: "row", xs: "column" }}
            justifyContent={{ md: "space-between", xs: "center" }}
            className="w-full"
          >
            <div className="flex justify-center md:justify-end py-2 md:py-4 px-2 text-sky-500">
              Total: {totalElements}
            </div>
            <div className="mb-2 flex justify-center">
              <Pagination
                count={totalPages}
                page={currentPage}
                color="primary"
                onChange={handlePageChange}
                size={isMobile ? "small" : "medium"}
                className="mb-4"
              />
            </div>
          </Stack>
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr className="divide-x divide-gray-200 ">
                <TableHeader heading="" />
                <TableHeader heading="Name" />
                <TableHeader heading="url" />
                <TableHeader heading="Start Date" />
                <TableHeader heading="End Date" />
                <TableHeader heading="Products" />
                <TableHeader heading="Is Active" />
                <TableHeader heading="" />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {schemes.map((s, index) => {
                return <SchemeRow s={s} index={index} brands={brands} />;
              })}
            </tbody>
          </table>
        </div>
      </div>

      {/* Scheme add / edit */}
      <AddEditSchemeModal
        open={open}
        setOpen={setOpen}
        brands={brands}
        periodFrom={periodFrom}
        periodTo={periodTo}
      />

      <ToastContainer />
    </>
  );
}

const AddEditSchemeModal = ({
  brands,
  open,
  setOpen,
  editScheme,
  periodFrom,
  periodTo,
}) => {
  const isEdit = editScheme ? true : false;

  useEffect(() => {
    if (editScheme) {
      setAddSchemePayload({
        id: editScheme.id,
        name: editScheme.name,
        imageUrl: editScheme.imageUrl,
        remark: editScheme.remark,
        groups: editScheme.groups,
        brandId: editScheme.brand.id,
        periodFrom: editScheme.periodFrom,
        periodTo: editScheme.periodTo,
        isActive: editScheme.isActive,
        products: editScheme.products,
        productDTOs: editScheme.products?.map((p) => ({
          id: p.id,
          productName:
            p.primaryName + (p.secondaryName ? " " + p.secondaryName : ""),
          startDate: p.startDate,
          endDate: p.endDate,
          amount: "" + p.amount,
          remark: p.remark,
        })),
      });
    } else {
      setAddSchemePayload({
        name: "",
        brandId: null,
        imageUrl: "-",
        periodFrom,
        periodTo,
        remark: "",
        displayOrder: 0,
        isActive: true,
        groupIds: [],
        groups: [],
        productDTOs: [],
        products: [],
      });
    }
  }, [editScheme]);

  const [addSchemePayload, setAddSchemePayload] = useState({
    name: "",
    brandId: null,
    imageUrl: "-",
    periodFrom,
    periodTo,
    remark: "",
    displayOrder: 0,
    isActive: true,
    groupIds: [],
    groups: [],
    productDTOs: [],
    products: [],
  });

  const save = () => {
    const valid =
      addSchemePayload?.groupIds.length > 0 && addSchemePayload.brandId;
    if (valid) {
      SchemesApi.AddScheme(addSchemePayload)
        .then((res) => {
          setOpen(false);
          toast("Success", {
            type: "success",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
          setAddSchemePayload({
            name: "",
            brandId: "",
            brandName: "",
            imageUrl: "-",
            periodFrom,
            periodTo,
            remark: "",
            displayOrder: 0,
            isActive: true,
            groupIds: [],
            productDTOs: [],
          });
        })
        .catch((err) => {
          if (err?.response?.data) {
            toast(err.response.status + " " + err?.response?.data?.message, {
              type: "error",
              position: toast.POSITION.TOP_CENTER,
              autoClose: 1000,
            });
          } else {
            toast(err?.response?.data?.message, {
              type: "error",
              position: toast.POSITION.TOP_CENTER,
              autoClose: 1000,
            });
          }
        });
      setOpen(false);
    } else {
      toast("Groups or Brand or Products is Empty", {
        type: "error",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
    }
  };

  const edit = () => {
    SchemesApi.UpdateScheme({
      ...addSchemePayload,
      products: undefined,
      groups: undefined,
    })
      .then((res) => {
        setOpen(false);
        toast("success", {
          type: "success",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
      })
      .catch((err) => {
        if (err?.response?.data) {
          toast(err.response.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        }
      });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setAddSchemePayload((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const getGroupsData = async (keyword) => {
    try {
      const { data } = await GroupsApi.GetGroupsListBySearch({
        keyword: keyword,
        page: 0,
        size: 99,
      });
      if (data.data) {
        return data.data;
      }
      return [];
    } catch (err) {
      toast(err?.response?.data?.message, {
        type: "error",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
      return [];
    }
  };

  const getProductsData = async (keyword) => {
    if (addSchemePayload.brandId) {
      try {
        const { data } = await ProductsApi.SearchProducts({
          page: 0,
          size: 10,
          keyword,
          brandId: addSchemePayload.brandId,
          isActive: true,
        });
        if (data.data) {
          return data.data;
        }
        return [];
      } catch (err) {
        toast(err?.response?.data?.message, {
          type: "error",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
        return [];
      }
    } else {
      toast("Please select Brand first", {
        type: "error",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
      return [];
    }
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => {
        setOpen(false);
        setAddSchemePayload({});
      }}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              setOpen(false);
              setAddSchemePayload({});
            }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {!editScheme ? "Add Scheme" : "Edit --->  " + editScheme?.name}
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth variant="standard" sx={{ marginY: 2 }}>
              <InputLabel id="demo-simple-select-standard-label">
                Select Brand
              </InputLabel>
              <Select
                required
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={addSchemePayload.brandId ? addSchemePayload.brandId : ""}
                label="Brand"
                onChange={(e) => {
                  setAddSchemePayload((prev) => ({
                    ...prev,
                    brandId: e.target.value,
                  }));
                }}
              >
                <MenuItem key={1222222} value={null}>
                  ---- Select Brand ----
                </MenuItem>
                {brands.map((i, index) => {
                  if (i.name) {
                    return (
                      <MenuItem key={index} value={i.id}>
                        {i.name}
                      </MenuItem>
                    );
                  } else {
                    return <></>;
                  }
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              id="standard-requried"
              required
              sx={{ marginY: 2 }}
              label="Scheme Name"
              name="name"
              variant="standard"
              value={addSchemePayload.name}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={1.5}>
            <TextField
              id="standard-requried"
              required
              fullWidth
              type="date"
              sx={{ marginY: 2 }}
              label="Start Date"
              variant="standard"
              onChange={(e) =>
                setAddSchemePayload((prev) => ({
                  ...prev,
                  periodFrom: e.target.value,
                }))
              }
              value={addSchemePayload.periodFrom}
            />
          </Grid>
          <Grid item xs={12} md={1.5}>
            <TextField
              fullWidth
              id="standard-requried"
              required
              type="date"
              sx={{ marginY: 2 }}
              label="End Date"
              variant="standard"
              onChange={(e) =>
                setAddSchemePayload((prev) => ({
                  ...prev,
                  periodTo: e.target.value,
                }))
              }
              value={addSchemePayload.periodTo}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth sx={{ marginTop: 1 }}>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Active
              </FormLabel>
              <RadioGroup
                value={addSchemePayload.isActive}
                row
                onChange={(e) => {
                  setAddSchemePayload((prev) => ({
                    ...prev,
                    isActive: e.target.value,
                  }));
                }}
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="isActive"
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="True"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="False"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="standard-requried"
              required
              sx={{ marginY: 2 }}
              label="Image Url"
              name="imageUrl"
              variant="standard"
              value={addSchemePayload.imageUrl}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="standard-requried"
              required
              sx={{ marginY: 2 }}
              label="Remark"
              name="remark"
              variant="standard"
              value={addSchemePayload.remark}
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <AutoComplete
              sx={{ marginY: 2 }}
              label="Select Groups"
              getOptionLabel={(option) => option["name"]}
              defaultValue={addSchemePayload.groups}
              fetchOptions={getGroupsData}
              onChange={(e, value) => {
                setAddSchemePayload((prev) => ({
                  ...prev,
                  // groups: value,
                  groupIds: value.map((g) => g.id),
                }));
              }}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <AutoComplete
              sx={{ marginY: 2 }}
              label="Select Products"
              labelKey="primaryName"
              getOptionLabel={(option) =>
                option["primaryName"] + " " + option["secondaryName"]
              }
              defaultValue={addSchemePayload.products}
              fetchOptions={getProductsData}
              onChange={(e, value) => {
                setAddSchemePayload((prev) => {
                  const productIds = prev.productDTOs.map((p) => p.id);

                  const newProductDTOs = value.map((p) => {
                    const index = productIds.indexOf(p.id);
                    if (index !== -1) {
                      return prev.productDTOs[index];
                    } else {
                      return {
                        id: p.id,
                        productName:
                          p.primaryName +
                          (p.secondaryName ? " " + p.secondaryName : ""),
                        startDate: addSchemePayload.periodFrom
                          ? addSchemePayload.periodFrom
                          : periodFrom,
                        endDate: addSchemePayload.periodTo
                          ? addSchemePayload.periodTo
                          : periodTo,
                        amount: "",
                        remark: "",
                      };
                    }
                  });

                  return { ...prev, productDTOs: newProductDTOs };
                });
              }}
            />
          </Grid>
        </Grid>
        <div className="overflow-x-auto">
          <AddSchemesTable
            addSchemePayload={addSchemePayload}
            setAddSchemePayload={setAddSchemePayload}
          />
        </div>
      </DialogContent>
      <DialogActions>
        {isEdit ? (
          <Button
            onClick={() => {
              edit();
            }}
          >
            Save Changes
          </Button>
        ) : (
          <Button
            onClick={() => {
              save();
            }}
          >
            Save{" "}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

const SchemeRow = ({ index, s, brands }) => {
  const [open, setOpen] = useState(false);
  const [openProducts, setOpenProducts] = useState(false);
  return (
    <>
      <Dialog
        maxWidth="lg"
        fullWidth
        open={openProducts}
        onClose={() => setOpenProducts(false)}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                setOpenProducts(false);
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 5, flex: 1 }} variant="h6" component="div">
              {s.name}
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <div className="flex flex-col ">
            <div className="mt-4">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-100">
                  <tr className="divide-x divide-gray-200 ">
                    <TableHeader heading="" />
                    <TableHeader heading="Name" />
                    <TableHeader heading="Start Date" />
                    <TableHeader heading="End Date" />
                    <TableHeader heading="Amount" />
                    <TableHeader heading="Remark" />
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {s.products?.length &&
                    s.products.map((p, i) => (
                      <tr
                        style={{ padding: "5px" }}
                        key={s.id}
                        className="divide-x divide-gray-200 hover:bg-slate-100"
                      >
                        <TableElement value={i + 1} />
                        <TableElement
                          value={p?.primaryName + p?.secondaryName}
                        />
                        <TableElement value={formatDate(p?.startDate)} />
                        <TableElement value={formatDate(p?.endDate)} />
                        <TableElement value={p?.amount} />
                        <TableElement value={p?.remark} />
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <AddEditSchemeModal
        setOpen={setOpen}
        open={open}
        brands={brands}
        editScheme={s}
      />
      <tr key={index} className="divide-x divide-gray-200 hover:bg-slate-100">
        <TableElement value={index + 1} />
        <TableElement value={`${s?.brand?.name} || ${s.name}`} />
        <TableElement
          value={
            s.imageUrl &&
            s.imageUrl !== "-" && (
              <>
                <a href={s.imageUrl} target="_blank" rel="noreferrer">
                  <Button variant="text" aria-describedby="url-popover">
                    Show
                  </Button>
                </a>
              </>
            )
          }
          type="element"
        />
        <TableElement value={<>{formatDate(s.periodFrom)}</>} type="element" />
        <TableElement value={<>{formatDate(s.periodTo)}</>} type="element" />
        <TableElement
          value={
            !!s.products?.length && (
              <>
                <Button
                  onClick={() => setOpenProducts(true)}
                  variant="text"
                  aria-describedby="url-popover"
                >
                  Show - {s.products.length}
                </Button>
              </>
            )
          }
        />
        <TableElement value={s.isActive} type="boolean" />
        <TableElement
          value={
            <span
              className="cursor-pointer text-sky-500 hover:text-sky-950 font-bold gr "
              onClick={() => {
                setOpen(true);
              }}
            >
              EDIT
            </span>
          }
          type="element"
        />
      </tr>
    </>
  );
};
