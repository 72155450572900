import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import { ComplaintsApi } from "apis/Complaint";
import { useNavigate } from "react-router";
import {
  Box,
  Button,
  createSvgIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  // FormControlLabel,
  // FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  // Radio,
  // RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import TableElement from "../../components/Table/TableElement";
import TableHeader from "components/Table/TableHeader";
import { useDispatch, useSelector } from "react-redux";
import CircularIndeterminateModal from "../../utils/Loading";
import { setLoadingPanel } from "../../redux/features/loading";
import { BrandsApi } from "apis/BrandsApi";
import ComplaintAddDialog from "components/Dialog/ComplaintAddDialog";

export default function Complaints() {
  const color = "light";
  const dispatch = useDispatch();
  const [brands, setBrands] = useState([]);
  const [ResolveComplaint, setResolveComplaint] = useState(false);
  // const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedBrandDialog, setSelectedBrandDialog] = useState(null);
  const [mobile, setMobile] = useState("");
  const [filterBrands, setFilterBrands] = useState("");
  const isLoading = useSelector((state) => state.loadingReducer.isLoading);
  const [selectedType, setSelectedType] = useState("COMPLAINT");
  const [ComplaintData, setComplaintData] = useState([]);
  const [AddComplaint, setAddComplaint] = useState(false);
  const navigate = useNavigate();
  const [id, setId] = useState("");

  const handleChange = (event) => {
    setSelectedType(event.target.value);
    setFilterBrands("");
  };

  const PlusIcon = createSvgIcon(
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 4.5v15m7.5-7.5h-15"
      />
    </svg>,
    "Plus"
  );

  const [formData, setFormData] = useState({
    brandId: "",
    type: "",
    description: "",
    // resolution: "",
    // isEscalated: false,
    // rating: 0,
  });

  const handleSubmitForm = async (payload, mobile) => {
    try {
      const validationError = validateFunction(payload);
      if (validationError) {
        toast(validationError, {
          type: "warning",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          style: {
            maxWidth: "90vw",
            width: "auto",
            margin: "5px auto",
            padding: "5px",
            fontSize: "14px",
          },
          closeButton: (
            <div className="flex justify-end">
              <button className="text-gray-500 hover:text-gray-800 focus:outline">
                <CloseIcon className="w-7 h-7" />
              </button>
            </div>
          ),
        });
        return;
      }
      if (mobile) {
        await ComplaintsApi.AddComplaintsMobileNumber(payload, mobile);
      } else {
        await ComplaintsApi.AddComplaints(payload);
      }
      fetchComplaints();
      toast("Your Complaint was created successfully.", {
        type: "success",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        style: {
          maxWidth: "90vw",
          width: "auto",
          margin: "5px auto",
          padding: "5px",
          fontSize: "14px",
        },
        closeButton: (
          <div className="flex justify-end">
            <button className="text-gray-500 hover:text-gray-800 focus:outline">
              <CloseIcon className="w-7 h-7" />
            </button>
          </div>
        ),
      });
      setAddComplaint(false);
      setFormData({
        brandId: "",
        type: "",
        description: "",
      });
    } catch (err) {
      const status = err?.response?.status;
      const message =
        err?.response?.data?.message || "An unexpected error occurred";
      toast(`${status ? `${status} ` : ""}${message}`, {
        type: "error",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        style: {
          maxWidth: "90vw",
          width: "auto",
          margin: "5px auto",
          padding: "5px",
          fontSize: "14px",
        },
        closeButton: (
          <div className="flex justify-end">
            <button className="text-gray-500 hover:text-gray-800 focus:outline">
              <CloseIcon className="w-7 h-7" />
            </button>
          </div>
        ),
      });
      if (status === 401) {
        localStorage.clear();
        navigate("/");
      }
    }
  };
  const validateFunction = (payload) => {
    if (!payload.type) return "Please select a complaint type.";
    if (!payload.brandId) return "Please select a brand.";
    if (!payload.description) return "Please provide a complaint description.";
    return null;
  };

  const getBrands = () => {
    dispatch(setLoadingPanel(true));
    BrandsApi.GetBrandsListByFilter({
      page: 0,
      size: 99,
    })
      .then((res) => {
        const filteredBrands = res.data.data.filter((d) => d.isActive);
        setBrands(filteredBrands);
        // setSelectedBrand(filteredBrands[0]);
        dispatch(setLoadingPanel(false));
      })
      .catch((err) => {
        dispatch(setLoadingPanel(false));
        if (err?.response?.data) {
          toast(err.response?.status + " " + err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            style: {
              maxWidth: "90vw",
              width: "auto",
              margin: "5px auto",
              padding: "5px",
              fontSize: "14px",
            },
            closeButton: (
              <div className="flex justify-end">
                <button className="text-gray-500 hover:text-gray-800 focus:outline">
                  <CloseIcon className="w-7 h-7" />
                </button>
              </div>
            ),
          });
        } else {
          toast(err?.response?.data?.message, {
            type: "error",
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            style: {
              maxWidth: "90vw",
              width: "auto",
              margin: "5px auto",
              padding: "5px",
              fontSize: "14px",
            },
            closeButton: (
              <div className="flex justify-end">
                <button className="text-gray-500 hover:text-gray-800 focus:outline">
                  <CloseIcon className="w-7 h-7" />
                </button>
              </div>
            ),
          });
        }
        if (err.response?.status === 401) {
          localStorage.clear();
          navigate("/");
        }
      });
  };

  const fetchComplaints = async () => {
    dispatch(setLoadingPanel(true));
    try {
      const { data } = await ComplaintsApi.GetComplaints(selectedType);
      setComplaintData(data.data);
      dispatch(setLoadingPanel(false));
    } catch (err) {
      dispatch(setLoadingPanel(false));
      const status = err?.response?.status;
      const message =
        err?.response?.data?.message || "An unexpected error occurred";
      toast(`${status ? `${status} ` : ""}${message}`, {
        type: "error",
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        style: {
          maxWidth: "90vw",
          width: "auto",
          margin: "5px auto",
          padding: "5px",
          fontSize: "14px",
        },
        closeButton: (
          <div className="flex justify-end">
            <button className="text-gray-500 hover:text-gray-800 focus:outline">
              <CloseIcon className="w-7 h-7" />
            </button>
          </div>
        ),
      });
      if (status === 401) {
        localStorage.clear();
        navigate("/");
      }
    }
  };

  useEffect(() => {
    fetchComplaints();
    getBrands();
  }, [selectedType]);

  const filterComplaintsByBrand = (complaints, selectedBrand) => {
    if (!selectedBrand) return complaints;
    return complaints.filter(
      (complaint) => complaint?.brand.id === selectedBrand.id
    );
  };

  const addScheme = () => {
    setAddComplaint(true);
  };

  const complaintTypes = [
    { label: "Complaint", value: "COMPLAINT" },
    { label: "Feedback", value: "FEEDBACK" },
    { label: "Suggestion", value: "SUGGESTION" },
    { label: "Opportunity", value: "OPPORTUNITY" },
  ];

  return (
    <>
      <CircularIndeterminateModal open={isLoading} />
      <div
        className={
          "relative flex md:flex-row flex-col min-w-0 break-words w-full p-4 mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="w-full lg:w-[70%] p-4 md:p-8">
          <Box className="w-full">
            <FormControl fullWidth>
              <InputLabel
                id="demo-simple-select-label"
                className="text-sm md:text-base"
              >
                Select Brand
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedType}
                label={
                  <span className="text-sm md:text-base font-medium">
                    Brand
                  </span>
                }
                onChange={handleChange}
                sx={{
                  fontSize: {
                    xs: "12px",
                    sm: "13px",
                    md: "13px",
                    lg: "16px",
                  },
                }}
                className="min-h-[40px] md:min-h-[52px]"
              >
                {["COMPLAINT", "FEEDBACK", "SUGGESTION", "OPPORTUNITY"].map(
                  (b, i) => (
                    <MenuItem
                      key={i}
                      value={b}
                      sx={{
                        fontSize: {
                          xs: "12px",
                          sm: "13px",
                          md: "13px",
                          lg: "16px",
                        },
                      }}
                    >
                      {b}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Box>
        </div>

        <div className="w-full lg:w-[30%] md:p-8 p-4 flex items-center">
          <Button
            onClick={addScheme}
            fullWidth
            variant="contained"
            color="primary"
            sx={{
              height: { xs: "40px", md: "52px" },
              fontSize: {
                xs: "12px",
                sm: "13px",
                md: "13px",
                lg: "16px",
              },
            }}
            startIcon={<PlusIcon className="h-4 w-4 md:h-5 md:w-5" />}
          >
            Add Complaint
          </Button>
        </div>
      </div>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 p-4 md:p-8 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <Grid item xs={12} md={6}>
          <Box className="w-full">
            <FormControl fullWidth>
              <InputLabel
                id="brand-select-label"
                className="text-sm md:text-base"
              >
                Brand
              </InputLabel>
              <Select
                labelId="brand-select-label"
                id="brand-select"
                value={filterBrands || ""}
                label={
                  <span className="text-sm md:text-base font-medium">
                    Brand
                  </span>
                }
                sx={{
                  fontSize: {
                    xs: "12px",
                    sm: "13x",
                    md: "13px",
                    lg: "16px",
                  },
                }}
                onChange={(e) => {
                  setFilterBrands(e.target.value);
                }}
                className="min-h-[40px] md:min-h-[52px]"
              >
                {brands.map((brand, index) => (
                  <MenuItem
                    key={index}
                    value={brand}
                    sx={{
                      fontSize: {
                        xs: "12px",
                        sm: "13x",
                        md: "13px",
                        lg: "16px",
                      },
                    }}
                  >
                    {brand.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
      </div>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="block w-full overflow-x-auto py-3 px-5 lg:p-8">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr className="divide-x divide-gray-200 ">
                <TableHeader heading="" />
                <TableHeader heading="Name" />
                <TableHeader heading="Complaint" />
                <TableHeader heading="Resolution" />
                <TableHeader heading="Rating" />
                <TableHeader heading="Active" />
                <TableHeader heading="Escalated" />
                <TableHeader heading="" />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {filterComplaintsByBrand(ComplaintData, filterBrands).map(
                (complaint, i) => {
                  return (
                    <tr
                      key={complaint.id}
                      className="divide-x divide-gray-200 hover:bg-slate-100"
                    >
                      <TableElement value={i + 1} />
                      <TableElement
                        value={
                          <span className="font-semibold">
                            {complaint.brand.name}
                          </span>
                        }
                        type="element"
                      />
                      <TableElement value={complaint.description} />
                      <TableElement value={complaint.resolution} />
                      <TableElement value={complaint.rating} />
                      <TableElement
                        value={complaint.brand.isActive}
                        type="boolean"
                      />
                      <TableElement
                        value={complaint.isEscalated}
                        type="boolean"
                      />
                      <TableElement
                        value={
                          <span
                            className="cursor-pointer text-sky-400 md:px-4 hover:text-sky-950 font-bold gr"
                            onClick={() => {
                              setResolveComplaint(true);
                              setId(complaint.id);
                            }}
                          >
                            Resolve
                          </span>
                        }
                      />
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
        </div>
      </div>
      {/*Add Complaint*/}

      <Dialog open={AddComplaint} onClose={() => setAddComplaint(false)}>
        <DialogTitle>Add Complaint</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="type-label" className="text-sm md:text-base">
                  Complaint Type
                </InputLabel>
                <Select
                  labelId="type-label"
                  id="type"
                  name="type"
                  sx={{
                    fontSize: {
                      xs: "12px",
                      sm: "13x",
                      md: "13px",
                      lg: "16px",
                    },
                  }}
                  value={formData.type || ""}
                  onChange={(e) =>
                    setFormData({ ...formData, type: e.target.value })
                  }
                >
                  {complaintTypes.map((type) => (
                    <MenuItem
                      sx={{
                        fontSize: {
                          xs: "12px",
                          sm: "13x",
                          md: "13px",
                          lg: "16px",
                        },
                      }}
                      key={type.value}
                      value={type.value}
                    >
                      {type.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className="w-full">
                <FormControl fullWidth>
                  <InputLabel
                    id="brand-select-label"
                    className="text-sm md:text-base"
                  >
                    Brand
                  </InputLabel>
                  <Select
                    labelId="brand-select-label"
                    id="brand-select"
                    value={selectedBrandDialog || ""}
                    name="Brand"
                    // label={
                    //   <span className="text-sm md:text-base font-medium">
                    //     Brand
                    //   </span>
                    // }
                    sx={{
                      fontSize: {
                        xs: "12px",
                        sm: "13x",
                        md: "13px",
                        lg: "16px",
                      },
                    }}
                    onChange={(e) => {
                      const { id } = e.target.value;
                      setSelectedBrandDialog(e.target.value);
                      setFormData({ ...formData, brandId: id });
                    }}
                    className="min-h-[40px] md:min-h-[52px]"
                  >
                    {brands.map((brand, index) => (
                      <MenuItem
                        key={index}
                        value={brand}
                        sx={{
                          fontSize: {
                            xs: "12px",
                            sm: "13x",
                            md: "13px",
                            lg: "16px",
                          },
                        }}
                      >
                        {brand.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="mobile"
                label={
                  <span className="text-xs sm:text-sm md:text-base lg:text-lg font-medium">
                    Mobile Number
                  </span>
                }
                inputProps={{
                  maxLength: 10,
                }}
                variant="standard"
                InputProps={{
                  sx: {
                    fontSize: {
                      xs: "12px",
                      sm: "13px",
                      md: "13px",
                      lg: "16px",
                    },
                  },
                }}
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <div style={{ width: "100%" }}>
                <TextField
                  fullWidth
                  id="outlined-multiline-static"
                  name="description"
                  label="Description"
                  variant="standard"
                  multiline
                  rows={4}
                  InputProps={{
                    sx: {
                      fontSize: {
                        xs: "12px",
                        sm: "13px",
                        md: "13px",
                        lg: "16px",
                      },
                    },
                  }}
                  value={formData.description}
                  onChange={(e) =>
                    setFormData({ ...formData, description: e.target.value })
                  }
                />
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleSubmitForm(formData, mobile);
            }}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <ComplaintAddDialog
        setResolveComplaint={setResolveComplaint}
        ResolveComplaint={ResolveComplaint}
        id={id}
        fetchComplaints={fetchComplaints}
      />
    </>
  );
}
