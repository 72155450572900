import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { toast } from "react-toastify";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@mui/material";

const AddNlcTable = ({ multipleNlcPayload, setMultipleNlcPayload }) => {
  const color = "light";
  const [applyToAllOpen, setApplyToAllOpen] = useState(false);
  const [applyToAllValue, setApplyToAllValue] = useState("");

  const applyValueToAll = (key) => {
    let remark = multipleNlcPayload[0].schemesDTO[0].remark;
    let periodFrom = multipleNlcPayload[0].schemesDTO[0].periodFrom;
    let periodTo = multipleNlcPayload[0].schemesDTO[0].periodTo;

    if (key === "amount") {
      setApplyToAllOpen(true);
    }

    let arr = multipleNlcPayload.map((e) => {
      switch (key) {
        case "remark":
          e.schemesDTO[0].remark = remark;
          break;
        case "periodFrom":
          e.schemesDTO[0].periodFrom = periodFrom;
          break;
        case "periodTo":
          e.schemesDTO[0].periodTo = periodTo;
          break;
        default:
          break;
      }
      return e;
    });

    setMultipleNlcPayload(arr);
  };

  return (
    <div
      className={
        "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
        (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
      }
    >
      <Dialog
        maxWidth="sm"
        open={applyToAllOpen}
        onClose={() => setApplyToAllOpen(false)}
      >
        <DialogTitle id="customized-dialog-title-subscriptions">
          Enter value
        </DialogTitle>
        <DialogContent dividers>
          <TextField
            fullWidth
            sx={{ marginY: 2 }}
            size="small"
            variant="standard"
            label="Amount"
            id="fullWidth"
            onChange={(e) => setApplyToAllValue(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              const nlcPayload = [];
              multipleNlcPayload.forEach((nlc) => {
                if (applyToAllValue.includes("%")) {
                  let calculatedAmount =
                    (nlc.selectedPrice.dp *
                      parseFloat(applyToAllValue.replace("%", ""))) /
                    100;

                  let schemesDTO = {
                    amount: calculatedAmount,
                  };
                  if (Array.isArray(nlc.schemesDTO) && nlc.schemesDTO.length) {
                    schemesDTO = {
                      ...nlc.schemesDTO[0],
                      amount: calculatedAmount,
                    };
                  }
                  const payload = { ...nlc, schemesDTO: [schemesDTO] };
                  nlcPayload.push(payload);
                } else {
                  let schemesDTO = {
                    amount: parseFloat(applyToAllValue),
                  };
                  if (Array.isArray(nlc.schemesDTO) && nlc.schemesDTO.length) {
                    schemesDTO = {
                      ...nlc.schemesDTO[0],
                      amount: parseFloat(applyToAllValue),
                    };
                  }
                  const payload = { ...nlc, schemesDTO: [schemesDTO] };
                  nlcPayload.push(payload);
                }
              });

              setMultipleNlcPayload(nlcPayload);
              setApplyToAllOpen(false);
            }}
            type="text"
          >
            Apply
          </Button>
        </DialogActions>
      </Dialog>
      <div className="block w-full overflow-x-auto p-8">
        <table className="items-center w-full bg-transparent border-collapse">
          <thead>
            <tr>
              <TableHeader name="Serial" color={color} />
              <TableHeader name="Model Name" color={color} />
              <TableHeader name="Price" color={color} />
              <TableHeader name="Amount" color={color} />
              <TableHeader name="Remark" color={color} />
              <TableHeader name="Period From" color={color} />
              <TableHeader name="Period To" color={color} />
            </tr>
          </thead>
          <tbody>
            {multipleNlcPayload.length > 0 ? (
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <Button onClick={() => applyValueToAll("amount")}>
                    Apply to all
                  </Button>
                </td>
                <td>
                  <Button onClick={() => applyValueToAll("remark")}>
                    Apply to all
                  </Button>
                </td>
                <td>
                  <Button onClick={() => applyValueToAll("periodFrom")}>
                    Apply to all
                  </Button>
                </td>
                <td>
                  <Button onClick={() => applyValueToAll("periodTo")}>
                    Apply to all
                  </Button>
                </td>
              </tr>
            ) : (
              <></>
            )}
            {multipleNlcPayload.map((model, i) => (
              <TableRow
                multipleNlcPayload={multipleNlcPayload}
                setMultipleNlcPayload={setMultipleNlcPayload}
                model={model}
                i={i}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export const AmountInput = ({
  model,
  i,
  setMultipleNlcPayload,
  multipleNlcPayload,
}) => {
  const amountChange = (e, model) => {
    if (e.target.value === "") {
      e.target.value = 0;
    }
    if (e.target.value.includes("%")) {
      if (parseFloat(e.target.value.replace("%", "")) > 100) {
        toast("Percentage cannot be more than 100", {
          type: "error",
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
      } else {
        let calculatedAmount =
          (model.selectedPrice.dp *
            parseFloat(e.target.value.replace("%", ""))) /
          100;

        let obj = {};
        obj.id = model?.schemesDTO[0]?.id;
        obj.remark = model.schemesDTO[0]?.remark;
        obj.periodFrom = model.schemesDTO[0]?.periodFrom;
        obj.periodTo = model.schemesDTO[0]?.periodTo;
        obj.amount = calculatedAmount.toFixed(2);
        model.schemesDTO = [obj];

        const index = multipleNlcPayload
          .map((e) => e.productId)
          .indexOf(model.productId);
        multipleNlcPayload[index] = model;
        setMultipleNlcPayload([...multipleNlcPayload]);
      }
    } else {
      let obj = {};
      obj.id = model?.schemesDTO[0]?.id;
      obj.remark = model.schemesDTO[0]?.remark;
      obj.periodFrom = model.schemesDTO[0]?.periodFrom;
      obj.periodTo = model.schemesDTO[0]?.periodTo;
      obj.amount =
        e.target.value.endsWith(".") ||
        e.target.value.endsWith(".0") ||
        e.target.value.endsWith(".00") ||
        e.target.value.endsWith(".000")
          ? e.target.value
          : parseFloat(e.target.value);
      model.schemesDTO = [obj];

      const index = multipleNlcPayload
        .map((e) => e.productId)
        .indexOf(model.productId);
      multipleNlcPayload[index] = model;
      setMultipleNlcPayload([...multipleNlcPayload]);
    }
  };
  return (
    <TextField
      key={i + 2}
      onChange={(e) => amountChange(e, model)}
      value={model.schemesDTO[0]?.amount}
      helperText="Add % sign to convert to percentage. Ex, 10%"
      size="small"
      id="outlined-basic"
      variant="outlined"
    />
  );
};

export const TableHeader = ({ name, color }) => {
  return (
    <th
      className={
        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
        (color === "light"
          ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
          : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
      }
    >
      {name}
    </th>
  );
};

export const TableValue = ({ value }) => {
  return (
    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
      {value}
    </td>
  );
};

export const TableRow = ({
  model,
  i,
  multipleNlcPayload,
  setMultipleNlcPayload,
}) => {
  const onPriceChange = (event, model) => {
    const data = event.target.value;
    model.priceId = data.id;
    model.selectedPrice = data;
    const index = multipleNlcPayload
      .map((e) => e.productId)
      .indexOf(model.productId);
    multipleNlcPayload[index] = model;
    setMultipleNlcPayload([...multipleNlcPayload]);
  };

  const remarkChange = (e, model) => {
    let obj = {};
    obj.id = model.schemesDTO[0].id;
    obj.amount = model.schemesDTO[0].amount;
    obj.remark = e.target.value;
    obj.periodFrom = model.schemesDTO[0]?.periodFrom;
    obj.periodTo = model.schemesDTO[0]?.periodTo;
    if (!model.schemesDTO[0].amount) {
      obj.amount = 0;
    }
    model.schemesDTO = [obj];

    const index = multipleNlcPayload
      .map((e) => e.productId)
      .indexOf(model.productId);
    multipleNlcPayload[index] = model;
    setMultipleNlcPayload([...multipleNlcPayload]);
  };

  return (
    <tr key={i + 1}>
      <TableValue value={i + 1} />
      <TableValue value={model.productName} />
      <TableValue
        value={
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel size="small" id="demo-simple-select-label">
                Select Price
              </InputLabel>
              <Select
                key={i + 1}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={model.selectedPrice}
                label="Brand"
                onChange={(e) => onPriceChange(e, model)}
              >
                {model.prices?.map((b, j) => (
                  <MenuItem key={j} value={b}>
                    DP:{b.dp}, MOP:{b.mop}, Group:{b.group.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        }
      />
      <TableValue
        value={
          <AmountInput
            model={model}
            i={i}
            multipleNlcPayload={multipleNlcPayload}
            setMultipleNlcPayload={setMultipleNlcPayload}
          />
        }
      />
      <TableValue
        value={
          <TextField
            key={i + 3}
            onChange={(e) => remarkChange(e, model)}
            size="small"
            value={model.schemesDTO[0].remark}
            id="outlined-basic"
            variant="outlined"
            fullWidth
            sx={{ width: "200px" }}
          />
        }
      />
      <TableValue
        value={
          <TextField
            type="date"
            value={
              model.schemesDTO[0]?.periodFrom
                ? model.schemesDTO[0].periodFrom
                : model.periodFrom
            }
            onChange={(e) => {
              let obj = {};
              obj.id = model.schemesDTO[0]?.id;
              obj.amount = model.schemesDTO[0]?.amount;
              obj.remark = model.schemesDTO[0]?.remark;
              obj.periodFrom = e.target.value;
              model.periodFrom = e.target.value;
              obj.periodTo = model.schemesDTO[0]?.periodTo;
              model.schemesDTO = [obj];
              const index = multipleNlcPayload
                .map((e) => e.productId)
                .indexOf(model.productId);
              multipleNlcPayload[index] = model;
              setMultipleNlcPayload([...multipleNlcPayload]);
            }}
            fullWidth
            id="outlined-basic"
            variant="outlined"
          />
        }
      />
      <TableValue
        value={
          <TextField
            type="date"
            value={
              model.schemesDTO[0]?.periodTo
                ? model.schemesDTO[0].periodTo
                : model.periodTo
            }
            onChange={(e) => {
              let obj = {};
              obj.id = model.schemesDTO[0]?.id;
              obj.amount = model.schemesDTO[0]?.amount;
              obj.remark = model.schemesDTO[0]?.remark;
              obj.periodFrom = model.schemesDTO[0]?.periodFrom;
              obj.periodTo = e.target.value;
              model.periodTo = e.target.value;
              model.schemesDTO = [obj];
              const index = multipleNlcPayload
                .map((e) => e.productId)
                .indexOf(model.productId);
              multipleNlcPayload[index] = model;
              setMultipleNlcPayload([...multipleNlcPayload]);
            }}
            fullWidth
            id="outlined-basic"
            variant="outlined"
          />
        }
      />
    </tr>
  );
};

export default AddNlcTable;
